<template>
  <div class="login" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs10 md5>
          <v-toolbar color="pink">
              <v-toolbar-title class="white--text">
                  Accedi
              </v-toolbar-title>
          </v-toolbar>
          <v-card>
              <v-form v-model="formValid" ref="form" lazy-formValidation @keyup.native.enter="submit">
                  <v-container>
                      <v-layout column>

                          <v-text-field
                                  label="Email"
                                  v-model="email"
                                  required
                                  :rules="emailRules"
                          ></v-text-field>

                          <v-text-field
                                  label="Password"
                                  v-model="password"
                                  type="password"
                                  required
                                  :rules="requiredRules"
                          ></v-text-field>

                          <v-checkbox label="Resta Connesso" dense v-model="rememberMe"></v-checkbox>

                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-container fluid>
                  <v-row>
                    <v-col cols="2" offset-sm="5"> 
                      <v-btn color="primary" @click="submit" :disabled="!formValid || loading">Entra</v-btn> 
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
          </v-card>
      </v-flex>
  </v-layout>
  </div>
</template>

<script>
import apiLogin from '@/js/pages/login';
import { emailRules, loginPasswordRules, requiredRules } from '@/js/validationRules';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      formValid: false,
      emailRules: emailRules,
      requiredRules: requiredRules,
      passwordRules: loginPasswordRules,
      rememberMe: false,
      loading: false,
    }
  },
  methods: {
    async submit(){
      this.loading = true;
      let data = {
        email: this.email,
        password: this.password
      };
      let token = await apiLogin.login(data);
      if(token) {
        let expires = 30;
        if (this.rememberMe) expires = 365;
        await this.generalFunction.setCookie('token', token, expires);
        await this.$router.push('/homepage').catch((e) => {console.log('errNav',e);});
      } else {
        this.EventBus.$emit('showAlert',['Credenziali Errate','error']);
      }
      this.loading = false;
    },
  },
}
</script>

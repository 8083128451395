import axios from 'axios';

export default {
    async login(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/login_client", params).then(response => {
            return response.data;
        });
    },
    async resetPass(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/init_reset_password_client", params).then(response => {
            return response.data;
        });
    },
}